@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");
@import url("https://rsms.me/inter/inter.css");

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: blackscale;
  font-feature-settings: "palt", "tnum", "cv02", "cv03", "cv04", "cv08", "cv10";
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

/* https://stackoverflow.com/questions/9218574/get-rid-of-magnifying-glass-in-ios-web-app */
/* NOTE: in case of multiple classes with not - https://css-tricks.com/css-not-with-multiple-classes/ */
:not(input):not(textarea) {
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

// NOTE: to prevent the bottom area covering the body content by a virtual keyboard, hide the bottom area on the max-height is less than 50vh.
.c-buttom-area {
  @media screen and (max-height: 50vh) {
    display: none;
  }
}

.u-readableAlphaNumerals {
  font-feature-settings: "palt", "tnum", "cv02", "cv03", "cv04", "cv05", "cv08",
    "cv10", "zero";
}
